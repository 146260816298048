<template>
  <div class="fantasysport-logo">
    <router-link :to="{ path: landingPagePath }">
      <img src="@/assets/fantasysport-logo.png" alt="fantasysport-logo">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    landingPagePath() {
      return `${this.$store.state.options.sportTypeSlug}/${this.$store.state.options.cc}`;
    },
  },
};
</script>

<style>

</style>
