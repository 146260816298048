<template>
  <div class="footer">
    <div class="footer__content" v-html="this.$store.state.options.footer.content"></div>
    <nav v-if="footerLinks.length" class="footer__nav">
      <router-link v-for="(footerLink, index) in footerLinks" :key="index" :to="{ name: footerLink.type }">{{ footerLink.label }}</router-link>
    </nav>
    <div class="disc" v-html="this.$store.state.options.footer.disclaimer"></div>
    <div class="footer-logos">
      <div class="plus-18-logo">
        <img src="@/assets/18pluslogo.svg" alt="18-plus-logo">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  computed: {
    footerLinks() {
      return this.$store.state.options.footerLinks;
    },
  },
};
</script>

<style lang="scss">
  .footer {
    background-color: transparent;
    font-size: 13px;
    text-align: center;
    padding: 1rem 1rem 2rem;
    h3 {
      margin-bottom: 10px;
      font-size: 20px;
    }
    p {
      font-size: 13px;
      margin-bottom: 4px;
      font-weight: 700;
    }
    a {
      font-weight: 700;
    }
  }
  .footer__content {
    color: #696969;
  }
  .plus-18-logo {
    margin-top: .5rem;
    img {
      width: 30px;
    }
  }
  .disc {
    font-size: 13px;
    font-weight: 700;
    color: #696969;
  }
  .footer__nav {
    margin-top: 1rem;
    a {
      text-decoration: underline;
      display: inline-block;
      margin: 5px 5px;
      color: #696969;
    }
  }
</style>
